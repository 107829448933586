export default [
  {
    path: 'buy-a-new-home-resources',
    name: 'newhome.resources',
    component: () =>
      import(
        /* webpackChunkName: "resources" */ '@/views/Home/BuyNewHomeResources'
      )
  },
  {
    path: 'buy-a-new-home-resources/new',
    name: 'newhome.resources.new',
    component: () =>
      import(
        /* webpackChunkName: "resources" */ '@/views/Home/BuyNewHomeResources/ResourceDetails'
      )
  },
  {
    path: 'buy-a-new-home-resources/:id',
    name: 'newhome.resources.details',
    component: () =>
      import(
        /* webpackChunkName: "resources" */ '@/views/Home/BuyNewHomeResources/ResourceDetails'
      )
  }
]
