import Api from '@/services/api'
import { each, find } from 'lodash'
import Question from '@/models/Question'

export default {
  namespaced: true,
  state: {
    list: [],
    meta: '',
    questionDetails: {}
  },

  mutations: {
    setQuestion(state, question) {
      state.questionDetails = question
    },

    setQuestionnaires(state, data) {
      state.list = data
    },

    setQuestionList(state, questions) {
      each(questions, question => {
        if (!find(state.list, { id: question.id })) {
          state.list.push(new Question(question))
        }
      })
    },

    setQuestionMeta(state, meta) {
      state.meta = meta
    },

    clearQuestions(state) {
      state.list = []
      state.meta = ''
    }
  },

  actions: {
    async getQuestion({ commit }, id) {
      const { data } = await Api.get(`admin/questions/${id}`)

      commit('setQuestion', data.data)
    },

    async updateQuestion({ commit }, payload) {
      const { data } = await Api.patch(`admin/questions/${payload.id}`, payload)

      commit('setQuestion', data.data)
    },

    async storeQuestion({ commit }, payload) {
      await Api.post(`admin/questions`, payload)
    },

    async deleteQuestion({ commit }, id) {
      await Api.delete(`admin/questions/${id}`, {
        data: {
          type: 'project-type'
        }
      })
    },

    async reorderQuestionnaires({ commit, state }, data) {
      data.map((question, index) => {
        question.order = index + 1
      })

      commit('setQuestionnaires', data)

      const payload = {
        type: 'project-type',
        questions: data.map(question => ({ id: question.id }))
      }

      Api.post(`questions/reorder`, payload)
    },

    async getOnboardingQuestions({ commit }, params) {
      const query = Question.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      query.where('type', params.type)

      const response = await query.params({ limit: 20 }).get()

      commit('setQuestionList', response.data)
      commit('setQuestionMeta', response.meta)
    }
  }
}
