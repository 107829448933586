import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    faqDetails: {}
  },

  mutations: {
    setList(state, faqs) {
      each(faqs, faq => {
        const exist = find(state.list, { id: faq.id })
        if (!exist) {
          state.list.push({
            id: faq.id,
            question: faq.question,
            answer: faq.answer,
            isShow: false
          })
        }
      })
    },

    setDetails(state, detail) {
      state.faqDetails = detail
    },

    clearDetails(state) {
      state.faqDetails = {}
    },

    clearList(state) {
      state.list = []
    }
  },

  actions: {
    async getFAQs({ commit }) {
      const { data } = await Api.get(`admin/faq`)
      commit('setList', data.data)
    },

    async getFaqDetails({ commit }, id) {
      const { data } = await Api.get(`admin/faq/${id}`)
      commit('setDetails', data.data)
    },

    async storeFaq({ commit }, form) {
      const { data } = await Api.post(`admin/faq`, form)

      commit('setDetails', data.data)
    },

    async updateFaq({ commit }, form) {
      const { data } = await Api.patch(`admin/faq/${form.id}`, form)

      commit('setDetails', data.data)
    },

    async deleteFaq({ commit }, id) {
      await Api.delete(`admin/faq/${id}`)

      commit('clearDetails')
      commit('clearList')
    }
  }
}
