import Api from '@/services/api'
import Milestone from '@/models/Milestone'

import { findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    milestoneDetails: {
      id: null
    }
  },

  mutations: {
    setMilestoneList(state, milestones) {
      state.list = milestones
    },

    clearMilestoneList(state) {
      state.list = []
    },

    updateListItem(state, milestone) {
      const itemIndex = findIndex(state.list, { id: milestone.id })

      Object.assign(state.list[itemIndex], milestone)
    }
  },

  actions: {
    async getMilestones({ commit }, params) {
      const { data } = await Milestone.get()

      commit('setMilestoneList', data)
    },

    updateMilestone({ commit }, milestone) {
      return Api.patch(`admin/milestones/${milestone.id}`, milestone)
    }
  }
}
