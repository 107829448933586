import Api from '@/services/api'
import Plan from '@/models/Plan'

import { each, find, findIndex } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    planDetails: {
      id: null
    }
  },

  mutations: {
    setList(state, plans) {
      each(plans, plan => {
        const exist = find(state.list, { id: plan.id })

        if (!exist) {
          state.list.push(plan)
        }
      })
    },

    setDetails(state, detail) {
      state.planDetails = detail
    },

    clearDetails(state) {
      state.planDetails = {}
    },

    clearList(state) {
      state.list = []
    },

    updateListItem(state, plan) {
      const itemIndex = findIndex(state.list, { id: plan.id })

      Object.assign(state.list[itemIndex], plan)
    }
  },

  actions: {
    async getPlans({ commit }) {
      const { data } = await Plan.get()
      commit('setList', data)
    },

    updatePlan({ commit }, plan) {
      return Api.patch(`admin/plans/${plan.id}`, plan)
    }
  }
}
