export default [
  {
    path: 'milestone-resources',
    name: 'milestone.resources',
    component: () =>
      import(/* webpackChunkName: "resources" */ '@/views/Home/Resources')
  },
  {
    path: 'milestone-resources/new',
    name: 'milestone.resources.new',
    component: () =>
      import(
        /* webpackChunkName: "resources" */ '@/views/Home/Resources/ResourceDetails'
      )
  },
  {
    path: 'milestone-resources/:id',
    name: 'milestone.resources.details',
    component: () =>
      import(
        /* webpackChunkName: "resources" */ '@/views/Home/Resources/ResourceDetails'
      )
  }
]
