import Api from '@/services/api'
import { each, find } from 'lodash'
import Package from '@/models/Package'

export default {
  namespaced: true,
  state: {
    list: [],
    meta: '',
    packageDetails: {}
  },

  mutations: {
    setPackageList(state, packages) {
      each(packages, projectPackage => {
        if (!find(state.list, { id: projectPackage.id })) {
          state.list.push(new Package(projectPackage))
        }
      })
    },

    setPackageMeta(state, meta) {
      state.meta = meta
    },

    setPackageDetails(state, packageDetails) {
      state.packageDetails = packageDetails
    },

    clearPackageList(state) {
      state.list = []
    }
  },

  actions: {
    async fetchPackages({ commit }, params) {
      const query = Package.page(params.page || 1).orderBy(params.sort)

      if (params.search) {
        query.where('search', params.search)
      }

      const response = await query.params({ limit: 20 }).get()

      commit('setPackageList', response.data)
      commit('setPackageMeta', response.meta)
    },

    async fetchPackageDetails({ commit }, id) {
      const { data } = await Api.get(`packages/${id}`)

      commit('setPackageDetails', data.data)
    },

    async exportPackages() {
      const file = await Api.get('admin/packages/export', {
        responseType: 'blob'
      })

      return file
    }
  }
}
