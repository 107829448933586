import Resource from '@/models/Resource'
import FormQuestion from '@/models/FormQuestion'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    resourceDetails: null,
    onboardingQuestions: [],
    formQuestions: [],
    formQuestionsMeta: {
      current_page: 0,
      last_page: 1
    }
  },

  mutations: {
    setResourceList(state, resources) {
      each(resources, resource => {
        const exist = find(state.list, { id: resource.id })
        if (!exist) {
          state.list.push(new Resource(resource))
        }
      })
    },

    clearResourceList(state) {
      state.list = []
    },

    setResourceListMeta(state, meta) {
      state.listMeta = meta
    },

    setResourceDetails(state, resource) {
      if (resource instanceof Resource) {
        state.resourceDetails = resource
      } else {
        state.resourceDetails = new Resource(resource)
      }
    },

    clearResourceDetails(state) {
      state.resourceDetails = null
    },

    setOnboardingQuestions(state, questions) {
      state.onboardingQuestions = questions
    },

    clearOnboardingQuestions(state) {
      state.onboardingQuestions = []
    },

    setFormQuestions(state, questions) {
      each(questions, question => {
        const exist = find(state.formQuestions, { id: question.id })
        if (!exist) {
          state.formQuestions.push(question)
        }
      })
    },

    setFormQuestionsMeta(state, meta) {
      state.formQuestionsMeta = meta
    },

    clearFormQuestions(state) {
      state.formQuestions = []
      state.formQuestionsMeta = ''
    }
  },

  actions: {
    async getResources({ commit }, params) {
      const query = Resource.page(params.page || 1).orderBy(params.sort)
      if (params.search) {
        query.where('search', params.search)
      }

      if (params.project_type) {
        query.where('project_type', params.project_type)
      }
      const res = await query.params({ limit: 25 }).get()
      commit('setResourceList', res.data)
      commit('setResourceListMeta', res.meta)
    },

    async getResourceDetails({ commit }, id) {
      const { data } = await Api.get(`admin/resources/${id}`)
      commit('setResourceDetails', data.data)
    },

    async createResource({ commit }, formData) {
      const { data } = await Api.post(`admin/resources`, formData)
      commit('setResourceDetails', data.data)
    },

    async updateResource({ commit }, formData) {
      const { data } = await Api.post(
        `admin/resources/${formData.get('id')}`,
        formData
      )
      commit('setResourceDetails', data.data)
    },

    async deleteResource({ commit }, id) {
      await Api.delete(`admin/resources/${id}`)
      commit('clearResourceDetails', id)
    },

    async exportToExcel() {
      const file = await Api.get('admin/resources/export', {
        responseType: 'blob'
      })
      return file
    },

    async getOnboardingQuestions({ commit }) {
      const { data } = await Api.get(`project-types`)
      const projectType = data.data[0]

      commit('setOnboardingQuestions', projectType.questionnaires)
    },

    async getFormQuestions({ commit }, params) {
      const query = FormQuestion.page(params.page || 1).orderBy(params.sort)
      query.where('type', 'milestone')

      if (params.milestone) {
        query.where('milestone', params.milestone)
      }

      if (params.choices) {
        query.where('choices', params.choices.join(','))
      }

      const res = await query.params({ limit: 15 }).get()

      commit('setFormQuestions', res.data)
      commit('setFormQuestionsMeta', res.meta)
    }
  }
}
