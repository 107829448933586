import Api from '@/services/api'

export default {
  namespaced: true,
  state: {
    list: [],
    questionnaires: [],
    selected: '',
    selectedQuestion: ''
  },

  mutations: {
    setList(state, data) {
      state.list = data
    },

    setSelectedProjectType(state, data) {
      state.selected = data
    },

    setQuestionnaires(state, data) {
      state.questionnaires = data
    },

    clearProjectTypes(state) {
      state.list = []
      state.questionnaires = []
    }
  },

  actions: {
    async fetchProjectTypes({ commit }, search) {
      let payload = ''

      if (search) {
        payload = decodeURI(
          new URLSearchParams([['filter[search]', search]]).toString()
        )
      }

      const { data } = await Api.get(`project-types${'?' + payload}`)
      const projectType = data.data[0]

      commit('setQuestionnaires', projectType.questionnaires)
      commit('setList', data.data)
    },

    async getProjectType({ commit }, id) {
      const { data } = await Api.get(`project-types/${id}`)

      commit('setSelectedProjectType', data.data)
      commit('setQuestionnaires', data.data.questionnaires)
    },

    async reorderQuestionnaires({ commit }, data) {
      data.map((question, index) => {
        question.order = index + 1
      })

      commit('setQuestionnaires', data)

      const payload = {
        type: 'project-type',
        questions: data.map(question => ({ id: question.id }))
      }

      Api.post(`admin/questions/reorder`, payload)
    },

    async exportOnboardingQuestion({ commit }) {
      const file = await Api.get('admin/questions/onboarding/export', {
        responseType: 'blob'
      })

      return file
    }
  }
}
