import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import projectTypes from './modules/project-types'
import question from './modules/question'
import formQuestion from './modules/formQuestion'
import projectPackage from './modules/project-package'
import milestone from './modules/milestone'
import notification from './modules/notification'
import report from './modules/report'
import versionControl from './modules/version-control'
import resource from './modules/resource'
import faq from './modules/faq'
import plans from './modules/plans'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    user,
    projectTypes,
    question,
    formQuestion,
    projectPackage,
    milestone,
    notification,
    report,
    versionControl,
    resource,
    faq,
    plans
  },
  state: {
    controls: {
      showDrawer: true
    },

    snackbar: {
      show: false,
      message: '',
      color: 'success'
    }
  },
  mutations: {
    toggleDrawer(state, value) {
      state.controls.showDrawer = value
    },
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar
    }
  },
  actions: {
    async showSnackbar({ commit }, params) {
      commit('setSnackbar', {
        show: true,
        message: params.message,
        color: params.color ?? 'success'
      })
    }
  }
})
