import FormQuestion from '@/models/FormQuestion'
import Api from '@/services/api'
import { each, find } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1
    },
    formQuestionDetails: null
  },

  mutations: {
    setFormQuestionList(state, formQuestions) {
      each(formQuestions, formQuestion => {
        const exist = find(state.list, { id: formQuestion.id })
        if (!exist) {
          state.list.push(new FormQuestion(formQuestion))
        }
      })
    },

    clearFormQuestionList(state) {
      state.list = []
    },

    setFormQuestionListMeta(state, meta) {
      state.listMeta = meta
    },

    setFormQuestionDetails(state, formQuestion) {
      if (formQuestion instanceof FormQuestion) {
        state.formQuestionDetails = formQuestion
      } else {
        state.formQuestionDetails = new FormQuestion(formQuestion)
      }
    },

    clearFormQuestionDetails(state) {
      state.formQuestionDetails = null
    }
  },

  actions: {
    async getFormQuestions({ commit }, params) {
      const query = FormQuestion.page(params.page || 1).orderBy(params.sort)
      query.where('type', 'milestone')
      if (params.search) {
        query.where('search', params.search)
      }
      if (params.milestone) {
        query.where('milestone', params.milestone)
      }
      const res = await query.get()
      commit('setFormQuestionList', res.data)
      commit('setFormQuestionListMeta', res.meta)
    },

    async getFormQuestionDetails({ commit }, id) {
      const { data } = await Api.get(`admin/questions/${id}`)
      commit('setFormQuestionDetails', data.data)
    },

    async createFormQuestion({ commit }, formData) {
      const { data } = await Api.post(`admin/questions`, formData)
      commit('setFormQuestionDetails', data.data)
    },

    async updateFormQuestion({ commit }, formData) {
      const { data } = await Api.put(`admin/questions/${formData.id}`, formData)
      commit('setFormQuestionDetails', data.data)
    },

    async deleteFormQuestion({ commit }, id) {
      await Api.delete(`admin/questions/${id}`)
      commit('clearFormQuestionDetails', id)
    },

    async exportToExcel() {
      const file = await Api.get('admin/questions/form/export', {
        responseType: 'blob'
      })
      return file
    }
  }
}
