import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth'
import settings from './settings'
import milestoneResources from './milestoneResouces'
import buyNewHomeResources from './buyNewHomeResources'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    auth,
    {
      path: '/',
      name: 'home',
      redirect: { name: 'users' },
      component: () => import(/* webpackChunkName: "home" */ '@/views/Home'),
      children: [
        {
          path: 'users',
          name: 'users',
          component: () =>
            import(/* webpackChunkName: "users" */ '@/views/Home/Users')
        },
        {
          path: 'users/:id',
          name: 'user.details',
          component: () =>
            import(
              /* webpackChunkName: "users" */ '@/views/Home/Users/UserDetails'
            )
        },
        {
          path: 'onboarding-questions',
          name: 'onboarding-questions',
          component: () => import('@/views/Home/OnboardingQuestions/index')
        },
        {
          path: 'onboarding-questions/create',
          name: 'onboarding-questions.create',
          component: () => import('@/views/Home/OnboardingQuestions/create')
        },
        {
          path: 'onboarding-questions/:id/edit',
          name: 'onboarding-questions.edit',
          component: () => import('@/views/Home/OnboardingQuestions/edit')
        },
        {
          path: 'packages',
          name: 'packages',
          component: () => import('@/views/Home/Packages')
        },
        {
          path: 'packages/:id',
          name: 'packages.details',
          component: () => import('@/views/Home/Packages/PackageDetails')
        },
        {
          path: 'form-questions',
          name: 'formQuestions',
          component: () =>
            import(
              /* webpackChunkName: "form-questions" */ '@/views/Home/FormQuestions'
            )
        },
        {
          path: 'form-questions/new',
          name: 'formQuestion.new',
          component: () =>
            import(
              /* webpackChunkName: "form-questions" */ '@/views/Home/FormQuestions/FormQuestionDetails'
            )
        },
        {
          path: 'form-questions/:id',
          name: 'formQuestion.details',
          component: () =>
            import(
              /* webpackChunkName: "form-questions" */ '@/views/Home/FormQuestions/FormQuestionDetails'
            )
        },
        {
          path: 'faqs',
          name: 'faqs',
          component: () =>
            import(/* webpackChunkName: "FAQs" */ '@/views/Home/FAQ')
        },
        {
          path: 'faqs/new',
          name: 'faqs.new',
          component: () =>
            import(/* webpackChunkName: "FAQs" */ '@/views/Home/FAQ/FaqDetails')
        },
        {
          path: 'faqs/:id',
          name: 'faqs.details',
          component: () =>
            import(/* webpackChunkName: "FAQs" */ '@/views/Home/FAQ/FaqDetails')
        },
        {
          path: 'plans',
          name: 'plans',
          component: () =>
            import(/* webpackChunkName: "Plans" */ '@/views/Home/Plans')
        },
        {
          path: 'milestones',
          name: 'milestones',
          component: () =>
            import(/* webpackChunkName: "Plans" */ '@/views/Home/Milestones')
        },
        ...milestoneResources,
        ...buyNewHomeResources,
        settings
      ]
    }
  ]
})
